import { Board, Team } from '@apps/www/src/__generated__/graphql';
import SVRedirect from '@apps/www/src/www/containers/SVRedirect';
import useAuthBoards from '@apps/www/src/www/hooks/useAuthBoards';
import useAuthHasTeamPrivileges from '@apps/www/src/www/hooks/useAuthHasTeamPrivileges';
import useAuthSettings from '@apps/www/src/www/hooks/useAuthSettings';
import useAuthTeam from '@apps/www/src/www/hooks/useAuthTeam';
import useFeatureFlag from '@apps/www/src/www/hooks/useFeatureFlag';
import useIsLoggedIn from '@apps/www/src/www/hooks/useIsLoggedIn';
import useIsTeamEnterprise from '@apps/www/src/www/hooks/useIsTeamEnterprise';
import SVA from '@pkgs/shared-client/components/SVA';
import SVLink from '@pkgs/shared-client/components/SVLink';
import config from '@pkgs/shared-client/config';
import BoardOwnershipType from '@pkgs/shared/enums/BoardOwnershipType';
import clsx from 'clsx';
import minimatch from 'minimatch';
import { useRouter } from 'next/router';

const _Label = ({
	children,
	to,
	path,
}: {
	children: React.ReactNode;
	to: string;
	path: string;
}) => {
	const router = useRouter();

	return (
		<SVA
			Component={SVLink}
			to={to}
			className={clsx(
				'w-fit max-w-full whitespace-nowrap text-2xl',
				minimatch(router.asPath, path) ? 'text-primary' : 'text-gray-500',
			)}
		>
			{children}
		</SVA>
	);
};

const _BoardLabel = ({ board, team }: { board: Board | null; team: Team }): JSX.Element => {
	const router = useRouter();

	const getTextColor = () => {
		if (board) {
			return minimatch(router.asPath, `/team/${board.slug}`) ||
				(minimatch(router.asPath, team.teamFeedAsHomepage ? '/' : '/team/') &&
					team.feedBoardID === board._id)
				? 'text-primary'
				: 'text-gray-500';
		}

		if (team.feedBoardID) {
			return minimatch(router.asPath, `/team/${team.feedBoardID}`)
				? 'text-primary'
				: 'text-gray-500';
		}

		if (team.teamFeedAsHomepage) {
			return minimatch(router.asPath, `/`) ? 'text-primary' : 'text-gray-500';
		}

		return minimatch(router.asPath, `/team/`) ? 'text-primary' : 'text-gray-500';
	};

	return (
		<div className={clsx('max-w-xs flex-shrink-0 overflow-hidden truncate', getTextColor())}>
			<SVA
				Component={SVLink}
				to={board || !team.teamFeedAsHomepage ? `/team/${board?.slug ?? ''}` : '/'}
				className={clsx('truncate whitespace-nowrap text-2xl', getTextColor())}
			>
				{board?.name ?? 'All'}
			</SVA>
		</div>
	);
};

const _BoardSelection = ({ teamBoards, team }: { teamBoards: Board[] | undefined; team: Team }) => (
	<div className="flex w-full flex-nowrap space-x-4 overflow-x-auto overflow-y-hidden">
		{!team.feedBoardID && <_BoardLabel board={null} team={team} />}
		{teamBoards?.map((board) => (
			<_BoardLabel key={board._id} board={board} team={team} />
		))}
	</div>
);

const SVTeamHomeContainer = ({ children }: { children: React.ReactNode }) => {
	const isLoggedIn = useIsLoggedIn();
	const team = useAuthTeam();
	const hasTeamPrivileges = useAuthHasTeamPrivileges();
	const authSettings = useAuthSettings(['isModerator']);
	const isModerator = authSettings?.isModerator;
	const isEnterprise = useIsTeamEnterprise(config);
	const teamBoards = useAuthBoards()?.filter(
		(board) => board.ownershipType === BoardOwnershipType.TEAM,
	) as Board[];
	const hasEnterprisePrivileges = isEnterprise || isModerator;
	const canAccessCourse = useFeatureFlag('course');

	if (!hasTeamPrivileges || !isLoggedIn || !team) {
		if (team) {
			return <SVRedirect to="/team/users/" />;
		} else {
			return <SVRedirect to="/team/create/" />;
		}
	}

	return (
		<div>
			{teamBoards.length > 0 && hasEnterprisePrivileges && (
				<div className="-sm:mx-[var(--page-margin)] mx-[calc(var(--page-margin)/2)] flex justify-between space-x-4 py-8">
					<_BoardSelection teamBoards={teamBoards} team={team as Team} />
					{canAccessCourse ? (
						<_Label to="/course/" path="/course/">
							Course
						</_Label>
					) : null}
				</div>
			)}
			{children}
		</div>
	);
};

export default SVTeamHomeContainer;
